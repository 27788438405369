import React, { lazy, Suspense, useEffect, useState, useCallback, useMemo } from 'react';
import { MapContainer, TileLayer, Marker, GeoJSON, useMap, useMapEvents,Polygon,Circle,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';
import './App.css';
import FilterByCountry from './components/FilterByCountry/FilterByCountry';
import CategoryFilterModal from './CategoryFilterModal';
import { useAppContext } from './AppContext';

const API_BASE_URL = 'https://api.mapmynews.com/app.php';

const SideBar = lazy(() => import('./SideBar'));
const Dialog = lazy(() => import('./Dialog'));

const subcategoryIcons = {
  'accident': '/PNG/PNG/accident.png',
  'accident/death': '/PNG/PNG/accident_death.png',
  'air defence': '/PNG/PNG/air defence.png',
  'airforce': '/PNG/PNG/air force.png',
  'business': '/PNG/PNG/business.png',
  'elections': '/PNG/PNG/elections.png',
  'emergency': '/PNG/PNG/emergency.png',
  'entertainment': '/PNG/PNG/entertainment.png',
  'explosion': '/PNG/PNG/explosion.png',
  'finance': '/PNG/PNG/finance.png',
  'financial institution': '/PNG/PNG/financial institution.png',
  'flight': '/PNG/PNG/flight.png',
  'general': '/PNG/PNG/General_ Announcement .png',
  'health': '/PNG/PNG/health.png',
  'legal': '/PNG/PNG/legal.png',
  'military': '/PNG/PNG/military.png',
  'missile': '/PNG/PNG/missile.png',
  'navy': '/PNG/PNG/navy.png',
  'navy ship': '/PNG/PNG/navyship.png',
  'science': '/PNG/PNG/science.png',
  'sports': '/PNG/PNG/sports.png',
  'technology': '/PNG/PNG/technology.png',
  'terror': '/PNG/PNG/terror.png',
  'vessel': '/PNG/PNG/vessel.png',
  'warzone': '/PNG/PNG/warzone.png',
};


const preloadMapTiles = (centerLat, centerLng, zoom) => {
  const tileUrl = (x, y, z) => `https://c.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  const numTiles = Math.pow(2, zoom);
  const x = Math.floor((centerLng + 180) / 360 * numTiles);
  const y = Math.floor((1 - Math.log(Math.tan(centerLat * Math.PI / 180) + 1 / Math.cos(centerLat * Math.PI / 180)) / Math.PI) / 2 * numTiles);

  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = tileUrl(x + i, y + j, zoom);
      document.head.appendChild(link);
    }
  }
};

const MapEvents = () => {
  const map = useMap();
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    if (state.mapCenter && state.mapZoom) {
      map.setView(state.mapCenter, state.mapZoom);
    }
  }, [state.mapCenter, state.mapZoom, map]);

  useEffect(() => {
    const handleMoveEnd = () => {
      dispatch({ 
        type: 'SET_MAP_VIEW', 
        payload: { 
          center: map.getCenter(), 
          zoom: map.getZoom() 
        } 
      });
    };

    map.on('moveend', handleMoveEnd);

    return () => {
      map.off('moveend', handleMoveEnd);
    };
  }, [map, dispatch]);

  return null;
};

const getLocationErrorMessage = (error) => {
  const errorMessages = {
    1: 'Location permission denied. Please enable location services.',
    2: 'Location information is unavailable.',
    3: 'The request to get user location timed out.',
  };
  return errorMessages[error.code] || 'Failed to get user location.';
};

L.Icon.Default.mergeOptions({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const getMarkerIcon = (subcategory) => {
  const iconUrl = subcategoryIcons[subcategory.toLowerCase()] || '/PNG/PNG/default.png';
  return L.icon({
    iconUrl: iconUrl,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32]
  });
};

const SetViewOnUser = React.memo(({ coords }) => {
  const map = useMap();
  useEffect(() => {
    if (coords) {
      map.setView(coords, 10);
      preloadMapTiles(coords[0], coords[1], 10);
    }
  }, [coords, map]);
  return null;
});


const MapContent = React.memo(() => {
  const { state, dispatch } = useAppContext();
  const map = useMap();

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleZoneClick = useCallback((zone) => {
    const zoneArticles = state.articles.filter(article => article.zone === zone);
    const otherArticles = state.articles.filter(article => article.zone !== zone);
    dispatch({ type: 'SET_GROUPED_ARTICLES', payload: [...zoneArticles, ...otherArticles] });
  }, [state.articles, handleMarkerClick, dispatch]);

  const renderZoneGeometry = useCallback((zone, data, color) => {
    if (data.type === 'Point') {
      return (
        <Circle
          center={[data.coordinates[1], data.coordinates[0]]}
          radius={20000}
          pathOptions={{ color: color, fillColor: color, fillOpacity: 0.2 }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
        </Circle>
      );
    } else if (data.type === 'Polygon') {
      return (
        <Polygon
          positions={data.coordinates[0].map(coord => [coord[1], coord[0]])}
          pathOptions={{ color: color }}
          eventHandlers={{ click: () => handleZoneClick(zone) }}
        >
          <Popup>{zone}</Popup>
        </Polygon>
      );
    } else {
      console.error('Unsupported GeoJSON type:', data.type);
      return null;
    }
  }, [handleZoneClick]);

  const groupMarkersByLocation = useCallback((articles) => {
    const groupedMarkers = {};
    articles.forEach((article) => {
      if (article.latitude && article.longitude) {
        const key = `${article.latitude},${article.longitude}`;
        if (!groupedMarkers[key]) {
          groupedMarkers[key] = [];
        }
        groupedMarkers[key].push(article);
      }
    });
    return groupedMarkers;
  }, []);

  const renderGroupedMarkers = useCallback((groupedMarkers) => {
    return Object.entries(groupedMarkers).map(([key, articles]) => {
      const [lat, lng] = key.split(',').map(Number);
      const offsetStep = 10;

      return articles.map((article, index) => {
        const offset = index * offsetStep;
        const icon = getMarkerIcon(article.subcategory);
        const adjustedLatLng = map.containerPointToLatLng(
          map.latLngToContainerPoint([lat, lng]).add([offset, 0])
        );

        return (
          <Marker
            key={`${article.id}-${index}`}
            position={adjustedLatLng}
            icon={icon}
            eventHandlers={{
              click: () => handleMarkerClick(article)
            }}
          />
        );
      });
    });
  }, [map, handleMarkerClick]);

  const groupedMarkers = useMemo(() => groupMarkersByLocation(state.filteredArticles), [state.filteredArticles, groupMarkersByLocation]);


  return (
    <>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      
      {Object.entries(state.geoJSONData).map(([zone, data]) => (
        <React.Fragment key={zone}>
          {renderZoneGeometry(zone, data, state.zoneColors[zone] || 'blue')}
        </React.Fragment>
      ))}
      
      {renderGroupedMarkers(groupedMarkers)}
    </>
  );
});


const MapComponent = () => {
  const { state, dispatch } = useAppContext();
  const [map, setMap] = useState(null);
  const [localState, setLocalState] = useState({
    isDialogOpen: false,
    isCategoryModalOpen: false, 
    loading: true,
    error: null,
    locationError: null, 
    selectedZoneArticles: [],
    showLocationError: true,
  });

  const mapCenter = useMemo(() => [20.593684, 78.96288], []); 
  const mapZoom = useMemo(() => 5, []);

  const api = useMemo(() => axios.create({
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' }
  }), []);

  const availableCountries = useMemo(() => {
    if (!state.articles || !Array.isArray(state.articles)) {
      return [];
    }
    const countries = new Set(state.articles.map(article => article.country));
    return Array.from(countries);
  }, [state.articles]);

  const filteredArticles = useMemo(() => {
    if (!state.articles || !Array.isArray(state.articles)) {
      return [];
    }
    return state.articles.filter(article => {
      const categoryMatch = state.activeCategory === 'all' || article.category === state.activeCategory;
      const countryMatch = !state.selectedCountry || article.country === state.selectedCountry;
      const selectedCategoryMatch = state.selectedCategories.length === 0 || state.selectedCategories.includes(article.category);
      const selectedSubCategoryMatch = state.selectedSubCategories.length === 0 || state.selectedSubCategories.includes(article.category);
      return categoryMatch && countryMatch && selectedCategoryMatch && selectedSubCategoryMatch;
    });
  }, [state.articles, state.selectedCountry, state.activeCategory, state.selectedCategories, state.selectedSubCategories]);

  const handleCloseErrorBanner = useCallback(() => {
    setLocalState(prev => ({ ...prev, showLocationError: false }));
  }, []);


  const getGeoJSONFromZone = useCallback(async (zoneName) => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: zoneName,
          format: 'json',
          polygon_geojson: 1,
        },
      });

      return response.data.length > 0 ? response.data[0].geojson : null;
    } catch (error) {
      console.error('Error fetching geojson:', error);
      return null;
    }
  }, []);


  const getUserLocation = useCallback(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          dispatch({ type: 'SET_USER_LOCATION', payload: [latitude, longitude] });
          dispatch({ type: 'SET_MAP_VIEW', payload: { center: [latitude, longitude], zoom: 10 } });
          setLocalState(prev => ({ ...prev, locationError: null, loading: false }));
        },
        (error) => {
          console.warn('Geolocation error:', error);
          dispatch({ type: 'SET_LOCATION_ERROR', payload: getLocationErrorMessage(error) });
          setLocalState(prev => ({ ...prev, loading: false }));
        },
        { 
          enableHighAccuracy: true, 
          timeout: 10000,
          maximumAge: 0 
        }
      );
    } else {
      dispatch({ type: 'SET_LOCATION_ERROR', payload: 'Geolocation is not supported by your browser.' });
      setLocalState(prev => ({ ...prev, loading: false }));
    }
  }, [dispatch]);



  const fetchArticlesAndGeoJSON = useCallback(async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await api.get('');
      const fetchedArticles = response.data;

      if (!Array.isArray(fetchedArticles)) {
        throw new Error('Invalid data format received from API');
      }

      dispatch({ type: 'SET_ARTICLES', payload: fetchedArticles });
      dispatch({ type: 'SET_LOADING', payload: false });

      const processGeoJSON = async () => {
        const uniqueZones = new Set(fetchedArticles.map((article) => article.zone));
        const geoData = {};
        const colors = {};
        
        await Promise.all(Array.from(uniqueZones).map(async (zone) => {
          if (zone) {
            try {
              const geoJSON = await getGeoJSONFromZone(zone);
              if (geoJSON) {
                geoData[zone] = geoJSON;
                const articleWithColor = fetchedArticles.find(article => article.zone === zone && article.color);
                colors[zone] = articleWithColor ? articleWithColor.color : 'blue';
              }
            } catch (error) {
              console.warn(`Failed to fetch GeoJSON for zone ${zone}:`, error);
            }
          }
        }));

        dispatch({ type: 'SET_GEO_JSON_DATA', payload: geoData });
        dispatch({ type: 'SET_ZONE_COLORS', payload: colors });
      };

      processGeoJSON();

    } catch (error) {
      console.error('Error fetching articles:', error);
      dispatch({ type: 'SET_ARTICLES', payload: [] });
      dispatch({ type: 'SET_LOADING', payload: false });
      setLocalState(prev => ({ ...prev, error: 'Failed to fetch news data. Please try again later.' }));
    }
  }, [api, dispatch]);

  useEffect(() => {
    preloadMapTiles(mapCenter[0], mapCenter[1], mapZoom);
    getUserLocation();
    fetchArticlesAndGeoJSON();
  }, [getUserLocation, fetchArticlesAndGeoJSON, mapCenter, mapZoom]);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      if (state.loading) {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }, 10000);
  
    return () => clearTimeout(loadingTimeout);
  }, [state.loading, dispatch]);

  const handleDialogClose = useCallback(() => {
    setLocalState(prev => ({ ...prev, isDialogOpen: false }));
  }, []);

  const handleMarkerClick = useCallback((article) => {
    dispatch({ type: 'SET_SELECTED_ARTICLE', payload: article });
    dispatch({ type: 'SET_DIALOG_OPEN', payload: true });
  }, [dispatch]);

  const handleCountryFilter = useCallback((country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
  }, [dispatch]);

  const resetCountryFilter = useCallback(() => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: 'in' });
  }, [dispatch]);

  const handleCategoryModal = useCallback(() => {
    setLocalState(prev => ({ ...prev, isCategoryModalOpen: !prev.isCategoryModalOpen }));
  }, []);

  const handleCategorySubmit = useCallback(({ categories, subCategories }) => {
    dispatch({ type: 'SET_SELECTED_CATEGORIES', payload: categories });
    dispatch({ type: 'SET_SELECTED_SUB_CATEGORIES', payload: subCategories });
  }, [dispatch]);

  if (state.loading) {
    return <div className="loading-container">Loading news data...</div>;
  }

  if (localState.error) {
    return <div className="error-container">{localState.error}</div>;
  }

  if (!state.articles || state.articles.length === 0) {
    return <div className="loading-container">No news data available.</div>;
  }

  return (
    <div className="app-container">
      {state.locationError && localState.showLocationError && (
        <div className="location-error-banner">
          {state.locationError}
          <button 
            onClick={handleCloseErrorBanner}
            className="close-error-button"
            aria-label="Close error message"
          >
            ×
          </button>
        </div>
      )}
      <FilterByCountry 
        onCountrySelect={handleCountryFilter}
        selectedCountry={state.selectedCountry}
        onReset={resetCountryFilter}
        availableCountries={availableCountries}
        onCategoryClick={handleCategoryModal}
      />
      <div className='map-sidebar'>
        <div className="map-container" role="application" aria-label="Interactive news map">
        <MapContainer
          center={state.mapCenter || [20.593684, 78.96288]}
          zoom={state.mapZoom || 2}
          style={{ height: '100%', width: '100%' }}
          whenCreated={setMap}
        >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MapEvents />
      <MapContent />
      <SetViewOnUser coords={state.userLocation} />
    </MapContainer>
        </div>
        <Suspense fallback={<div role="status" aria-live="polite">Loading sidebar...</div>}>
          <SideBar
            articles={state.groupedArticles || filteredArticles}
            selectedArticle={state.selectedArticle?.id}
            onArticleClick={handleMarkerClick}
          />
        </Suspense>
        <Suspense fallback={<div role="status" aria-live="polite">Loading dialog...</div>}>
          <Dialog />
        </Suspense>
        {localState.isCategoryModalOpen && (
          <CategoryFilterModal 
            onClose={() => setLocalState(prev => ({ ...prev, isCategoryModalOpen: false }))}
            onSubmit={handleCategorySubmit}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(MapComponent);
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { useAppContext } from '../../AppContext';
import MobileMenu from '../MobileMenu/MobileMenu';
import './FilterByCountry.css'

const countryNames = {
  'in': 'India',
  'ua': 'Ukraine',
  'cn': 'China',
};

const FilterByCountry = ({ onCategoryClick }) => {
  const { state, dispatch } = useAppContext();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
  };

  const getButtonClass = (countryCode) => {
    if (state.selectedCountry === countryCode) return 'active';
    if (state.articles.some(article => article.country === countryCode)) return 'available';
    return 'unavailable';
  };

  const today = format(new Date(), 'MMMM d, yyyy');

  const DesktopView = () => (
    <div className='desktop-view'>
      <div className="country-buttons">
        <img src='mmn4 1.png' alt="Logo" className='logo' />
        <div className="country-container">
          {Object.entries(countryNames).map(([code, name]) => (
            <button
              key={code}
              onClick={() => handleClick(code)}
              className={`country-button ${getButtonClass(code)}`}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
      <div className="date-display">
        <p>{today}</p>
        <button className="category-desktop-filter-button" onClick={onCategoryClick}>
          {'Select Category >'}
        </button>
      </div>
    </div>
  );

  const MobileView = () => (
    <div className="mobile-view">
      <img src='mmn4 1.png' alt="Logo" className='logo' />
      <p className="mobile-date">{today}</p>
      <MobileMenu onCategoryClick={onCategoryClick} />
    </div>
  );

  return (
    <div className='filter-container'>
      {isMobile ? <MobileView /> : <DesktopView />}
    </div>
  );
};

export default FilterByCountry;
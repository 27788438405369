import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { useAppContext } from '../../AppContext';
import './MobileMenu.css'
const countryNames = {
  'in': 'India',
  'ua': 'Ukraine',
  'cn': 'China',
};

const MobileMenu = ({ onCategoryClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useAppContext();

  const handleCountryClick = (country) => {
    dispatch({ type: 'SET_SELECTED_COUNTRY', payload: country });
    setIsOpen(false);
  };

  const handleCategoryClick = () => {
    onCategoryClick();
    setIsOpen(false);
  };

  const getButtonClass = (countryCode) => {
    if (state.selectedCountry === countryCode) return 'active';
    if (state.articles.some(article => article.country === countryCode)) return 'available';
    return 'unavailable';
  };

  return (
    <div className="mobile-menu-container">
      <button onClick={() => setIsOpen(!isOpen)} className="hamburger-button">
        {isOpen ? <X size={24} /> : <Menu size={24} />}
      </button>
      {isOpen && (
        <div className="mobile-menu-drawer">
            <button className="category-filter-button" onClick={handleCategoryClick}>
                Select Category
            </button>
            <hr style={{width:"200px"}}/>
          <div className="country-options">
            {Object.entries(countryNames).map(([code, name]) => (
              <button
                key={code}
                onClick={() => handleCountryClick(code)}
                className={`country-button ${getButtonClass(code)}`}
              >
                {name}
              </button>
            ))}
          </div>

        </div>
      )}
    </div>
  );
};

export default MobileMenu;